var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-row',[_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12"}},[_c('page-content',{attrs:{"breadcrumbTitle":true,"breadcrumb":[
        {
          title: 'Components',
          link: '/components/components-page',
        },
        {
          title: 'Data Entry',
        },
      ]},scopedSlots:_vm._u([{key:"desc",fn:function(){return [_c('p',{staticClass:"h5 mb-0 mt-24 hp-text-color-black-0"},[_vm._v(" Switch styling is supported on "),_c('code',[_vm._v("<b-form-checkbox>")]),_vm._v(" and "),_c('code',[_vm._v("<b-form-checkbox-group>")]),_vm._v(" components. ")])]},proxy:true}])})],1),_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12"}},[_c('individual')],1),_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12"}},[_c('grouped')],1),_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12"}},[_c('sizing')],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }